<template>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-container>
        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>? {{ $t("are you sure delete ") }}{{ title }}</h2>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn color="green" class="white--text" depressed @click="deleteHomework(homeWorkId)"
                :loading="deleteBtnLoading">{{ $t("submit") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteAttachmentDialog" width="500">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <h2>? {{ $t("are you sure delete ") }}{{ name }}</h2>
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn color="green" class="white--text" depressed @click="deleteAttachment(attachmentId)"
                :loading="deleteBtnLoading">{{ $t("submit") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <template v-for="(info, key, index) of lesson">
            <v-col md="4" :key="index" v-if="info.value">
              <h3 class="ml-5">
                {{ $t(info.desc) }} :
                <span style="font-weight: 100">{{
      info.desc == "created at"
        ? dateFormater(info.value)
        : info.value
    }}</span>
              </h3>
            </v-col>
          </template>
        </v-row>
        <v-divider class="mt-5" />
        <!-- <h3 class="tests mt">{{ $t("lecture") }}</h3> -->
        <v-row class="mt-2 mb-2">
          <v-col md="6">
            <h3 class="ml-5">
              {{ $t("lecture title") }} :
              <span style="font-weight: 100" v-if="copy_lesson.lecture !== null">
                {{ copy_lesson.lecture.title }}</span>
            </h3>
          </v-col>
          <v-col md="6">
            <h3 class="ml-5">
              {{ $t("unit title") }} :
              <span style="font-weight: 100" v-if="copy_lesson.lecture !== null">
                {{ copy_lesson.lecture.unit.name }}</span>
            </h3>
          </v-col>
        </v-row>
        <v-divider />
        <v-row align="center" class="mt-2">
          <v-col md="2">
            <h3 class="tests">{{ $t("Homeworks") }}</h3>
          </v-col>
          <v-col md="2">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t("add homework") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t("add homework") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode" :label="$t('title')" v-model="newHomework.title" outlined dense color="#757575"
                        type="text"></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
                        v-model="newHomework.due_to"></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-text-field :dark="$store.state.isDarkMode" :label="$t('description')" v-model="newHomework.description" outlined dense
                        color="#757575" type="text"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitNewHomework" :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn">{{ $t("add") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <template>
          <v-sheet color="var(--bg-color--background)" max-width="100%">
            <v-slide-group v-model="model" show-arrows>
              <v-slide-group-item v-for="item in lesson.homeworks" :key="item.id">
                <v-card elevation="2" class="card">
                  <v-container>
                    <v-row justify="start" align="center">
                      <v-col md="9">
                        <p>
                          <span class="titlee">
                            {{ $t("homework title") }} :</span>
                          {{ item.title }}
                        </p>
                        <p>
                          <span class="titlee"> {{ $t("due to") }} :</span>
                          {{ item.due_to }}
                        </p>
                      </v-col>
                      <v-col md="1"><v-icon color="green"
                          @click="$router.push(`/view-homework/${item.id}`)">mdi-eye</v-icon>
                      </v-col>
                      <v-col md="1">
                        <v-icon color="red" @click="() => {
        deleteDialog = true;
        homeWorkId = item.id;
        title = item.title;
      }
      ">mdi-delete</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>

            <v-expand-transition>
              <v-sheet v-if="model != null" height="200">
                <div class="d-flex fill-height align-center justify-center">
                  <h3 class="text-h6">Selected {{ model }}</h3>
                </div>
              </v-sheet>
            </v-expand-transition>
          </v-sheet>
        </template>

        <v-divider class="mt-5" />
        <v-row align="center" class="mt-2">
          <v-col md="2">
            <h3 class="tests">{{ $t("attachments") }}</h3>
          </v-col>

          <v-col md="2" style="display: flex; justify: start">
            <div>
              <app-attachment-modal path="lesson" @fetchData="fetchData"></app-attachment-modal>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col md="2" v-for="attachment in lesson.attachments" :key="attachment.id">
            <div class="cardd">
              <div>
                <v-row>
                  <v-col md="9">
                    <img class="preview-img" :src="attachment.path" />
                  </v-col>
                  <v-col md="3">
                    <v-icon color="red" @click="() => {
        deleteAttachmentDialog = true;
        attachmentId = attachment.id;
        name = attachment.name;
      }
      ">mdi-delete</v-icon>
                    <div class="mt-5">
                      <FileComponent :Id="attachment.id"></FileComponent>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col md="9">
                    <div class="test" :title="attachment.name">
                      <span>
                        {{ attachment.name }} -

                        {{ Math.round(attachment.size / 1000) + "kb" }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import editLessonModal from "./editLessonModal.vue";
import attachmentModalVue from "./attachmentModal.vue";
import attachmentModal from "./attachmentModal.vue";
import FileComponent from "./download.vue";
export default {
  components: {
    appEditLessonModal: editLessonModal,
    appAttachmentModal: attachmentModal,
    FileComponent,
  },
  data() {
    return {
      file: null,
      url: null,
      attachmentId: null,
      submitBtnLoading: false,
      due_to_Dialog: false,
      homeWorkId: "",
      model: null,
      newHomework: {
        title: "",
        name: "",
        description: "",
        due_to: "",
        lesson_id: this.$route.params.id,
      },
      title: "",
      deleteBtnLoading: false,
      deleteDialog: false,
      deleteAttachmentDialog: false,
      lessonId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters({
      lesson: "lessons/getLessonData",
      copy_lesson: "lessons/getCopyLesson",
      loadingStatus: "lessons/getLoadingStatus",
      getTableLoadingStatus: "teachers/getTableLoading",
      homeworks: "homeworks/getHomeWorks",
    }),
    disableSubmitBtn() {
      if (
        !this.newHomework.title ||
        !this.newHomework.description ||
        !this.newHomework.due_to
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    ...mapActions({
      fetchLesson: "lessons/fetchLesson",
      fetchHomeworks: "homeworks/fetchHomeworks",
    }),
    fetchData(page) {
      this.dialog = false;
      this.fetchLesson(this.lessonId);
      this.fetchHomeworks({
        params: {
          page,
        },
      });
    },
    async deleteAttachment(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/attachment/${id}`);
        this.deleteAttachmentDialog = false;
        this.$Notifications(
          this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    async deleteHomework(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`/homework/${id}`);

        this.deleteDialog = false;
        this.$Notifications(
          this.$t('delete success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    // async previewFile(id) {
    //   console.log(id);
    //   try {
    //     let res = await axios.get(`/attachment/${id}`);
    //     console.log(res);
    //     console.log(this.url);
    //   } catch (err) {
    //     console.log("err", err);
    //   } finally {
    //   }
    // },
    dateFormater(date) {
      var local = new Date(date);
      return local.toLocaleDateString();
    },
    async submitNewHomework() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/homework", this.newHomework);
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }

    this.fetchData(1);
  },
};
</script>
<style scoped>
.desc {
  width: 100%;
  background-color: rgb(218, 130, 15);
}

.attachment-btn {
  background-color: green;
  cursor: pointer;
  opacity: 0.8;
  color: white;
  padding: 0.5vw 0.9vw;
  border-radius: 0.2vw;
  margin-top: 3vw;
}

.title {
  width: 100%;

  /* background-color: red; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.icon {
  display: flex;
  justify-content: end;
  color: green;
}

.cardd {
  /* display: flex;
  justify-content: space-between; */
  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  /* height: 10vw; */
  /* height: 100px; */
  padding: 5px 10px;
  /* background-color: red; */
  /* overflow: hidden; */
  /* cursor: pointer; */
}

.cardd .preview-img {
  width: 100%;
}

.preview-card::-webkit-scrollbar {
  display: none;
}

.times {
  color: red;
  font-size: 1.5vw;
}

.test {
  /* background-color: palevioletred; */
  text-align: left;
  width: 100%;
  height: 40px;
  font-size: 0.8vw;
  margin-top: 5px;
  overflow: auto;
}

.test::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.test {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.text {
  border: 1px solid rgb(182, 179, 179);
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: gray;
  font-size: 1vw;
  box-shadow: 1px 2px #dad9d9;
}

.titlee {
  font-weight: 700;
  color: rgb(0, 0, 0);
}

.tests {
  font-size: 2vw;
}

.mt {
  margin-top: 2vw;
}

.textIn {
  font-size: 0.5vw;
  display: flex;
  justify-content: start;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  height: 40px;
  background-color: pink;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment {
  width: 100%;
  height: 7vw;
  border-radius: 1vw;
  background-color: lightgrey;
  text-align: center;
  border: 1px solid grey;
}

.card {
  width: 95%;
  /* min-height: 6vw; */

  border-radius: 0.7vw;
  margin-top: 1vw;
}
</style>
